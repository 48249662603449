import React, { useEffect } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import ProxyTypeCard from "./ProxyTypeCard"
import ProxyDetailCard from "./ProxyDetailCard"
import ProxyBillingCycleCard from "./ProxyBillingCycleCard"
import AddProxyButton from "./AddProxyButton"
import WaitlistAddedModal from "./WaitlistAddedModal"
import ProxyLocationTypeCard from "./ProxyLocationTypeCard"

import { stringify } from "query-string"
import MultiLocationProductFeatures from "./MultiLocationProductFeatures"
import MultiLocationTypeCard from "./MultiLocationTypeCard"

const MainContainer = styled.div`
  @media screen and (max-width: 960px) {
    margin: 0px 30px;
  }

  .proxy-type-heading {
    text-transform: capitalize;
  }
  // .subscription-length-heading {
  //   margin-top: 50px;
  // }
`

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;

  > * {
    @media (max-width: 960px) {
      width: 100%;
      padding: 0;
    }
    @media (min-width: 961px) and (max-width: 1060px) {
      width: 46%;
    }
    @media (min-width: 1061px) {
      width: 33.33%;
    }
  }
`

const ChooseProxyHeading = styled.p`
  font-size: 22px;
  font-weight: 550;
`

const LocationSelector = styled.button`
  background: #ffffff;
  border: 1px solid #c4c7ce;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  box-shadow: ${props =>
    props.active
      ? `#419bf9 0px 0px 0px 4px, rgba(106, 191, 131, 0.1) 0px 0px 0px 1px,
          rgba(106, 191, 131, 0.1) 0px 30px 90px 0px,
          rgba(158, 158, 163, 0.25) 0px 2px 18px 0px;`
      : `rgba(65, 155, 249, 0.1) 0px 0px 0px 1px,
          rgba(65, 155, 249, 0) 0px 0px 0px 0px,
          rgba(65, 155, 249, 0.08) 0px 12px 50px 0px,
          rgba(158, 158, 163, 0.25) 0px 2px 10px 0px`};
  border-radius: 15px;
  padding: 14px 24px;
  font-weight: 700;
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.6;
  width: auto;

  @media (max-width: 960px) {
    width: 100%;
    margin: 0 0 14px;
  }

  .blue {
    color: #419bf9;
  }
`
const MultiLocationFeaturesList = styled.div`
  .heading {
    font-weight: 600;
    margin-left: 22px;
  }
  ul {
    font-size: 16px;
    margin-bottom: 0;
    li {
      line-height: 1.5rem;
    }
  }
`
const MultiLocationFeaturesListContainer = styled.div`
  background: #fafafa;
  border-radius: 22px;
  padding-top: 1rem;
`

export default function AddProxyApp({
  isPublic,
  selectedTier2Id,
  selectedTier3Id,
  setSelectedTier2Id,
  setSelectedTier3Id,
  addWaitlistItem,
  waitlists,
  showWaitlistSuccess,
  waitlistedItemID,
  hideWaitlistModal,
  fetchWaitlist,
  ipRotations,
  proxyTypes,
  notLaunchedProxyTypes,
  selectedBillingCycle,
  billingCycles,
  setBillingCycle,
  proxyLocationTypes,
  selectedProxyLocationType,
  setProxyLocationType,
  multilocationProxyTypes,
  ...props
}) {
  const {
    locations,
    locationID,
    setLocation,
    fetchLocations,
    multilocations,
    notLaunchedMultilocations,
    setMultilocation,
    selectedMultilocation,
  } = props

  useEffect(() => {
    if (!isPublic) {
      fetchWaitlist()
    }
    fetchLocations()
  }, [fetchWaitlist, fetchLocations, isPublic])

  // Handle here instead of checkout for analytics conflicting with redirects
  const handleClick = () => {
    const params = {
      isPublic,
      selectedTier2Id,
      selectedTier3Id,
      locationID,
      selectedBillingCycle,
    }
    if (selectedProxyLocationType === "multiple") {
      params["multilocationId"] = selectedMultilocation.id
    }
    if (isPublic) {
      localStorage.removeItem("user")
      localStorage.setItem(
        "targetUrl",
        "/checkout?" +
          stringify({
            ...params,
          })
      )
      navigate(`/createaccount?form=select_method`)
      return
    }
    navigate(
      "/checkout?" +
        stringify({
          ...params,
        })
    )
  }

  const canProceedNext = () => {
    if (selectedProxyLocationType === "multiple") {
      if (!selectedMultilocation) {
        return false
      }
      if (
        notLaunchedMultilocations.find(
          mlp => mlp.id === selectedMultilocation?.id
        )
      ) {
        return false
      }
    }
    if (
      selectedTier2Id &&
      selectedTier3Id &&
      locationID &&
      selectedBillingCycle
    ) {
      return true
    }
    return false
  }

  return (
    <React.Fragment>
      <MainContainer>
        <ChooseProxyHeading className="proxy-type-heading">
          Location
        </ChooseProxyHeading>
        <React.Fragment>
          <CardsContainer style={{ margin: "10px 0 40px" }}>
            {proxyLocationTypes.map(elem => (
              <ProxyLocationTypeCard
                key={elem.id}
                available={elem.available}
                selectedId={selectedProxyLocationType}
                setSelectedId={setProxyLocationType}
                id={elem.id}
                title={elem.title}
              />
            ))}
          </CardsContainer>
        </React.Fragment>
        {selectedProxyLocationType === "single" && (
          <React.Fragment>
            <CardsContainer style={{ margin: "10px 0 40px" }}>
              {locations.map(elem => (
                <LocationSelector
                  key={elem.id}
                  {...elem}
                  active={elem.id === locationID}
                  onClick={() => setLocation(elem)}
                >
                  City: <span className="blue">{elem.city}</span> <br />
                  State: <span className="blue">{elem.state}</span>
                </LocationSelector>
              ))}
            </CardsContainer>

            {proxyTypes.length !== 0 && (
              <React.Fragment>
                <ChooseProxyHeading className="proxy-type-heading">
                  Choose Subscription length
                </ChooseProxyHeading>
                <CardsContainer>
                  {billingCycles.map(elem => (
                    <ProxyBillingCycleCard
                      key={elem.code}
                      available={elem.available}
                      selectedId={selectedBillingCycle}
                      setSelectedId={setBillingCycle}
                      id={elem.code}
                      title={elem.title.toUpperCase()}
                      addWaitlistItem={addWaitlistItem}
                      waitlists={waitlists}
                    />
                  ))}
                </CardsContainer>
              </React.Fragment>
            )}

            {(proxyTypes.length > 0 ||
              (notLaunchedProxyTypes && !!notLaunchedProxyTypes.length)) && (
              <React.Fragment>
                <ChooseProxyHeading className="proxy-type-heading">
                  Type
                </ChooseProxyHeading>
                <CardsContainer>
                  {proxyTypes.map(elem => (
                    <ProxyTypeCard
                      key={elem.id}
                      id={elem.id}
                      selectedId={selectedTier2Id}
                      setSelectedId={setSelectedTier2Id}
                      available={elem.available}
                      title={elem.title}
                      servicesOffered={elem.servicesOffered.split(",")}
                      price={elem.price}
                      imageUrl={elem.imageUrl}
                      addWaitlistItem={addWaitlistItem}
                      waitlists={waitlists}
                      weeklyPrice={elem.weeklyPrice}
                      selectedBillingCycle={selectedBillingCycle}
                    />
                  ))}
                  {notLaunchedProxyTypes.map(elem => (
                    <ProxyTypeCard
                      key={elem.id}
                      id={elem.id}
                      selectedId={selectedTier2Id}
                      setSelectedId={setSelectedTier2Id}
                      available={elem.available}
                      title={elem.title}
                      servicesOffered={elem.servicesOffered.split(",")}
                      price={elem.price}
                      imageUrl={elem.imageUrl}
                      addWaitlistItem={addWaitlistItem}
                      waitlists={waitlists}
                      weeklyPrice={elem.weeklyPrice}
                      selectedBillingCycle={selectedBillingCycle}
                    />
                  ))}
                </CardsContainer>
              </React.Fragment>
            )}

            {proxyTypes.length !== 0 && (
              <React.Fragment>
                <ChooseProxyHeading className="proxy-type-heading">
                  Choose IP rotation
                </ChooseProxyHeading>
                <CardsContainer>
                  {ipRotations.map(elem => (
                    <ProxyDetailCard
                      key={elem.id}
                      available={elem.available}
                      selectedId={selectedTier3Id}
                      setSelectedId={setSelectedTier3Id}
                      id={elem.id}
                      title={elem.title.toUpperCase()}
                      shortDescription={elem.shortDescription}
                      price={elem.price}
                      weeklyPrice={elem.weeklyPrice}
                      selectedBillingCycle={selectedBillingCycle}
                      imageUrl={elem.imageUrl}
                      addWaitlistItem={addWaitlistItem}
                      waitlists={waitlists}
                    ></ProxyDetailCard>
                  ))}
                </CardsContainer>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {selectedProxyLocationType === "multiple" && (
          <React.Fragment>
            <CardsContainer style={{ margin: "10px 0 20px" }}>
              {multilocations.map(elem => (
                <>
                  <LocationSelector
                    key={elem.id}
                    {...elem}
                    active={elem.id === selectedMultilocation?.id}
                    onClick={() => setMultilocation(elem)}
                  >
                    Name: <span className="blue">{elem.title}</span> <br />
                    Type: <span className="blue">{elem.featureType}</span>
                  </LocationSelector>
                </>
              ))}
              {notLaunchedMultilocations.map(elem => (
                <>
                  <MultiLocationTypeCard
                    key={elem.id}
                    {...elem}
                    active={elem.id === selectedMultilocation?.id}
                    onClick={() => setMultilocation(elem)}
                    addWaitlistItem={addWaitlistItem}
                    waitlists={waitlists}
                    available={false}
                  />
                </>
              ))}
            </CardsContainer>
            <MultiLocationFeaturesListContainer>
              <MultiLocationFeaturesList>
                <p className="heading">What's included:</p>
                <ul>
                  {selectedMultilocation?.servicesOffered &&
                    selectedMultilocation?.servicesOffered
                      .split(",")
                      .map((service, idx) => <li key={idx}>{service}</li>)}
                </ul>
              </MultiLocationFeaturesList>

              <MultiLocationProductFeatures
                proxyTypes={multilocationProxyTypes}
              />
            </MultiLocationFeaturesListContainer>
            <ChooseProxyHeading className="proxy-type-heading">
              Choose Subscription length
            </ChooseProxyHeading>
            <CardsContainer>
              {billingCycles.map(elem => (
                <ProxyBillingCycleCard
                  key={elem.code}
                  available={elem.available}
                  selectedId={selectedBillingCycle}
                  setSelectedId={setBillingCycle}
                  id={elem.code}
                  title={elem.title.toUpperCase()}
                  addWaitlistItem={addWaitlistItem}
                  waitlists={waitlists}
                  isMultiLocation={selectedProxyLocationType === "multiple"}
                  selectedMultilocation={selectedMultilocation}
                />
              ))}
            </CardsContainer>
          </React.Fragment>
        )}

        {canProceedNext() ? (
          <div id="next_link" onClick={handleClick}>
            <AddProxyButton>Next</AddProxyButton>
          </div>
        ) : (
          <AddProxyButton disabled>Next</AddProxyButton>
        )}

        <WaitlistAddedModal
          show={showWaitlistSuccess}
          waitlistedItemID={waitlistedItemID}
          features={[
            ...proxyTypes,
            ...notLaunchedProxyTypes,
            ...notLaunchedMultilocations,
          ]}
          allIps={ipRotations}
          allProducts={[]}
          onHide={hideWaitlistModal}
        />
      </MainContainer>
    </React.Fragment>
  )
}
